import React from 'react';
import { graphql } from 'gatsby';

import { SEO, Layout, ContactForm, Mapbox } from '@/components';
import { PageContext } from '@/domain/pageContext';
import { PContact } from '@/domain/Contact';
interface Props{
  pageContext: PageContext;
    
  data: {
    prismicContactpage: PContact;
  }
}


const Contact = ({ pageContext, data: { prismicContactpage } }: Props): JSX.Element => {
    const { dataHeader, alternate } = pageContext;
    const { uid, lang, alternate_languages } = prismicContactpage;
    const {
      seo_url,
      seo_title,
      seo_description,
      seo_keywords,
      contact_title,
      contact_infos
    } = prismicContactpage.data;

    const selectLang = {
        lang: lang,
        uid: uid,
        alternate_languages: alternate_languages
    }


    const seo = {
      lang: lang.split('-')[0],
      url: `${lang.split('-')[0]}/${seo_url.text}`,
      title: seo_title.text,
      description: seo_description.text,
      keywords: seo_keywords.text
    }

    const linkAlternate = alternate?.map(data => ({
      lang: data.lang,
      url: `${data.lang.split('-')[0]}/${data.uid}`
    }))

    return (
        <Layout header={dataHeader} selectLang={selectLang} >
            <SEO lang={seo.lang} title={`Global Electrics | ${seo.title}`} url={seo.url} description={seo.description} keywords={seo.keywords} alternate={linkAlternate} />
            <ContactForm lang={lang} contactTitle={contact_title} contactInfos={contact_infos} />
            {/* <Mapbox /> */}
        </Layout>
    );
};

export default Contact;


export const query = graphql`
query ($id: String!) {
  prismicContactpage(id: {eq: $id}) {
        uid
        lang
        alternate_languages {
          lang
          uid
        }
        data {
          contact_title {
            raw
          }
          contact_infos {
            raw
          }
          seo_url {
            text
          }
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_keywords {
            text
          }
        }
  }
}
`